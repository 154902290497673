import axios from "axios";

let request = axios.create({

    timeout: 50000
})

request.interceptors.request.use(config => {


    return config;
});

request.interceptors.response.use((response) => {
    return response.data;
}, (error) => {

    return Promise.reject(error);
});
export default request;