import Vue from "vue";

import VueRouter from "vue-router";

Vue.use(VueRouter);

import Apps from "@/components/Apps.vue";

const router = new VueRouter({
  mode: 'history', // 使用 history 模式
  routes: [
    {
      path: "/Apps",
      component: Apps,
    },

    {
      path: "/",
      component: Apps,
      redirect: "/Apps",
    },
  ],
});

export default router;
  